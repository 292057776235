import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import dayjs from 'dayjs';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  loading: false,
  api: ConstantAPI.EVENT,
  visible: false,
  listEvent: [],
  listCamera: [],
  listGroupCamera: [],
  total: 0,
  statisticDoor: {},
  listHumanImg: [],
  eventSocket: [],
  videEvent:null,
  url: null,
});

const actions = {
  async getAll({ state, commit }, params) {
    try {
      commit('setApi', params.responseType);
      const response = await DataService.callApi(state.url, null, {
        ...params,
        page: params.page - 1,
        dateFrom:
          params && params.dateFrom
            ? dayjs(params.dateFrom).format('YYYY-MM-DD HH:mm:ss')
            : null,
        dateTo:
          params && params.dateTo
            ? dayjs(params.dateTo).format('YYYY-MM-DD HH:mm:ss')
            : null,
      });
      let success;
      checkResponse(response, () => {
        success = true;
        state.listEvent = response.data.content ? response.data.content : [];
        state.total = response.data.totalElements;
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async infiniteScrollEvent({state, commit}, params) {
    try {
      commit('setApi', params.responseType);
      const response = await DataService.callApi(state.url, null, {
        ...params,
        page: params.page - 1,
      });
      let success;
      if(params.reset) {
        state.listEvent = [];
      };
      checkResponse(response, () => {
        success = true;
        state.listEvent = response.data.content ? state.listEvent.concat(response.data.content) : state.listEvent;
        state.total = response.data.totalElements;
      });
      return success;
    } catch (er) {
      console.log(er);
    }
    
  },
  selectAllEvent({ state }) {
    for (let i in state.listEvent) {
      state.listEvent[i].checked = true;
    }
  },
  deselectAllEvent({ state }) {
    for (let i in state.listEvent) {
      state.listEvent[i].checked = false;
    }
  },
  async getCameraInGroup({ state }, params) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.CAMERA.GET_CAMERA_IN_GROUP,
        null,
        params
      );
      let success;
      checkResponse(response, () => {
        success = true;
        state.listCamera = response.data ? response.data : [];
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getGroupCamera({ state }) {
    try {
      const response = await DataService.callApi(
        ConstantAPI.CAMERA.GROUP_CAMERA
      );
      state.listGroupCamera = response;
    } catch (err) {
      console.log(err);
    }
  },
  async delete({ state }, ids) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('event.confirm-delete-event'))) {
        const response = await DataService.delete(state.api.DELETE.url + ids);
        let success;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: t('common.notification'),
            description: t('event.delete-event-success'),
            duration: 4,
          });
          return true;
        });
        return success;
      }
    } catch (err) {
      console.log(err);
    }
  },
  async getAllById({ state, commit }, params) {
    try {
      const response = await DataService.callApi(
        state.api.GET_ALL,
        null,
       params
      );
      commit('setList', response.data.content ? response.data.content : []);
      commit('setTotal', response.data.totalElements);
    } catch (err) {
      console.log(err);
    }
  },
  async statisticDoor({ state }, params) {
    try {
      const response = await DataService.callApi(
        state.api.STATISTIC_DOOR,
        null,
        {
          ...params,
          dateFrom:
            params && params.dateFrom
              ? dayjs(params.dateFrom).set('seconds', 0).toDate()
              : null,
          dateTo:
            params && params.dateTo
              ? dayjs(params.dateTo).set('seconds', 59).toDate()
              : null,
        }
      );
      let success;
      checkResponse(response, () => {
        success = true;
        state.statisticDoor = response.data ? response.data : {};
      });
      return success;
    } catch (err) {
      console.log(err);
    }
  },
  async getAllMonitor({state}, params) {
    try {
      const response = await DataService.callApi(state.api.MONITOR_EVENT, null, {
        ...params,
        page: params.page - 1,
        dateFrom:
          params && params.dateFrom
            ? dayjs(params.dateFrom).set('seconds', 0).toDate()
            : null,
        dateTo:
          params && params.dateTo
            ? dayjs(params.dateTo).set('seconds', 59).toDate()
            : null,
      });
      let success;
      checkResponse(response, () => {
        success = true;
        state.listEvent = response.data.content ? response.data.content : [];
        state.total = response.data.totalElements;
      });
      return success;
    } catch(e) {
      console.log(e)
    }
  },
  async getHumanImg({state}, params) {
    try {
      const response = await DataService.callApi(state.api.HUMAN_IMAGE, null, params);
      let success;
      checkResponse(response, () => {
        success = true;
        state.listHumanImg = response.data;
      });
      return success;
    } catch(e) {
      console.log(e)
    }
  },
  addEvent({state}, event) {
    state.listEvent.unshift(event);
  },
  addEventSocket({state}, event) {
    state.eventSocket = event;
  },
  clearEvent({ state }) {
    state.listEvent = [];
  },
  async getVideoEvent({state}, params) {
    try {
      const response = await DataService.callApi(state.api.VIDEO_EVENT, null, params);
      return response;
    } catch(e) {
      console.log(e)
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
