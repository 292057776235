import { RESPONSE_TYPE } from "@/util/common-constant";

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setList(state, list) {
    state.listEvent = list;
  },
  setTotal(state, total) {
    state.total = total
  },
  setApi(state, responseType) {
    switch (responseType) {
      case RESPONSE_TYPE.IN_OUT:
        state.url = state.api.IN_OUT_EVENT;
        break;
      case RESPONSE_TYPE.DOOR:
        state.url = state.api.LANE_CONTROL_EVENT;
        break;
      case RESPONSE_TYPE.INTRUSION:
        state.url = state.api.INTRUSION_EVENT;
        break;
      case RESPONSE_TYPE.SENSITIVE:
        state.url = state.api.SENSITIVE_EVENT;
        break;
      case RESPONSE_TYPE.HUMAN_COUNT:
        state.url = state.api.HUMAN_COUNT_EVENT;
        break;
      default:
        state.url = state.api.IN_OUT_EVENT;
    }
  },
};
