import {i18n} from "@/main";
// import { graphic } from 'echarts';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setChartPieIn(state) {
    const {t} = i18n.global
    state.chartPieIn = [
      {value: state.dataStaff.totalIn, name: t('access_control.employee')},
      {value: state.dataCustomer.totalIn, name: t('access_control.client')},
      {value: state.dataStranger.totalIn, name: t('access_control.stranger')},
      {value: state.dataBlacklist.totalIn, name: t('access_control.blacklist')},
      {value: state.dataVip.totalIn, name: t('access_control.VIP')},
      {value: state.dataUnknown.totalIn, name: t('common.unknown')},
    ]
  },
  setChartPieOut(state) {
    const {t} = i18n.global
    state.chartPieOut = [
      {value: state.dataStaff.totalOut, name: t('access_control.employee')},
      {value: state.dataCustomer.totalOut, name: t('access_control.client')},
      {value: state.dataStranger.totalOut, name: t('access_control.stranger')},
      {value: state.dataBlacklist.totalOut, name: t('access_control.blacklist')},
      {value: state.dataVip.totalOut, name: t('access_control.VIP')},
      {value: state.dataUnknown.totalOut, name: t('common.unknown')},
    ]
  },
  setChartLineIn(state, statisticIn) {
    const {t} = i18n.global
    state.chartLineIn.data = statisticIn.map(e => e.key)
    state.chartLineIn.series = [
      {
        name: t('access_control.employee'),
        data: statisticIn.map(e => e.staff),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#62b2fd',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('access_control.client'),
        data: statisticIn.map(e => e.customer),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#9bdfc4',
          
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('access_control.stranger'),
        data: statisticIn.map(e => e.stranger),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#f99bab',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('access_control.blacklist'),
        data: statisticIn.map(e => e.blacklist),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#434343',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('access_control.VIP'),
        data: statisticIn.map(e => e.vip),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#D98911',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('common.unknown'),
        data: statisticIn.map(e => e.unknown),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#c4c4c4',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
    ]
  },
  setChartLineOut(state, statisticOut) {
    const {t} = i18n.global
    state.chartLineOut.data = statisticOut.map(e => e.key)
    state.chartLineOut.series = [
      {
        name: t('access_control.employee'),
        data: statisticOut.map(e => e.staff),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#62b2fd',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('access_control.client'),
        data: statisticOut.map(e => e.customer),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#9bdfc4',
          
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('access_control.stranger'),
        data: statisticOut.map(e => e.stranger),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#f99bab',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('access_control.blacklist'),
        data: statisticOut.map(e => e.blacklist),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#434343',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('access_control.VIP'),
        data: statisticOut.map(e => e.vip),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#D98911',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
      {
        name: t('common.unknown'),
        data: statisticOut.map(e => e.unknown),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#c4c4c4',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
      },
    ]
  },
  setChartMonthLineIn(state, statisticIn) {
    const {t} = i18n.global
    state.chartMonthLineIn.data = statisticIn.map(e => e.key)
    state.chartMonthLineIn.series = [
      {
        name: t('access_control.VIP'),
        data: statisticIn.map(e => e.vip),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#D98911',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#D98911'
      },
      {
        name: t('access_control.stranger'),
        data: statisticIn.map(e => e.stranger),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#f99bab',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#f99bab'
      },
      {
        name: t('access_control.employee'),
        data: statisticIn.map(e => e.staff),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#62b2fd',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#62b2fd'
      },
      {
        name: t('access_control.client'),
        data: statisticIn.map(e => e.customer),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#9bdfc4',
          
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#9bdfc4'
      },   
      {
        name: t('access_control.blacklist'),
        data: statisticIn.map(e => e.blacklist),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#434343',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#434343'
      },
    
    ]
  },
  setChartMonthLineOut(state, statisticOut) {
    const {t} = i18n.global
    state.chartMonthLineOut.data = statisticOut.map(e => e.key)
    state.chartMonthLineOut.series = [
      {
        name: t('access_control.VIP'),
        data: statisticOut.map(e => e.vip),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#D98911',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#D98911'
      },
      {
        name: t('access_control.stranger'),
        data: statisticOut.map(e => e.stranger),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#f99bab',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#f99bab'
      },
      {
        name: t('access_control.employee'),
        data: statisticOut.map(e => e.staff),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#62b2fd',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#62b2fd'
      },
      {
        name: t('access_control.client'),
        data: statisticOut.map(e => e.customer),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#9bdfc4',
          
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#9bdfc4'
      },
      {
        name: t('access_control.blacklist'),
        data: statisticOut.map(e => e.blacklist),
        type: 'line',
        emphasis: {
          focus: 'series'
        },
        label: {
          show: true,
          color: '#fff',
          backgroundColor: '#434343',
          padding: [3, 3, 3, 3],
          borderRadius: [10, 10, 10, 10],
          fontSize: 10
        },
        color: '#434343'
      },
    ]
  },
  setTotal(state, data) {
    state.totalIn = data.stranger.totalIn + data.staff.totalIn + data.customer.totalIn + data.vip.totalIn + data.blacklist.totalIn;
    state.totalOut = data.stranger.totalOut + data.staff.totalOut + data.customer.totalOut + data.vip.totalOut + data.blacklist.totalOut;
    
  }
};
