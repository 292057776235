const CMS_SERVICE = '/user-service';
const HRM_SYSTEM = '/hrm-system';
const VMS_SERVICE = '/vms-service';

export default {
  report_service: {
    OVERVIEW: {
      url: CMS_SERVICE + '/overview',
      method: 'GET',
    },
    OVERVIEW_LINE: {
      url: CMS_SERVICE + '/chart',
      method: 'GET',
    },
    TOTAL_ARTICLE_BY_CREATED: {
      url: CMS_SERVICE + '/chart/article-by-created',
      method: 'GET',
    },
    TOTAL_ARTICLE_BY_STATUS: {
      url: CMS_SERVICE + '/chart/article-by-status',
      method: 'GET',
    },
  },
  role: {
    GET_ALL: {
      url: CMS_SERVICE + '/role/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/role',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/role',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/role',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/role',
      method: 'DELETE',
    },
    GET_MENU: {
      url: CMS_SERVICE + '/menu',
      method: 'GET',
    },
  },
  role_menu: {
    GET_BY_ROLE: {
      url: CMS_SERVICE + '/role-menu',
      method: 'GET',
    },
  },
  user_role: {
    GET_BY_USER: {
      url: CMS_SERVICE + '/user-role',
      method: 'GET',
    },
  },
  category: {
    ALL: {
      url: CMS_SERVICE + '/category/get-all',
      method: 'GET',
    },
    ALL_WITHOUT_TREE: {
      url: CMS_SERVICE + '/category/get-all-without-tree',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/category',
      method: 'GET',
    },
    DETAIL: {
      url: CMS_SERVICE + '/category/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/category',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/category',
      method: 'PUT',
    },
    UPDATE_STATUS: {
      url: CMS_SERVICE + '/category/update-status',
      method: 'PUT',
    },

    DELETE: {
      url: CMS_SERVICE + '/category',
      method: 'DELETE',
    },
  },
  menu: {
    SEARCH: {
      url: CMS_SERVICE + '/menu',
      method: 'GET',
    },
    GET_MENU: {
      url: CMS_SERVICE + '/menu/only-menu',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/menu',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/menu',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/menu',
      method: 'DELETE',
    },
  },
  user: {
    SEARCH: {
      url: CMS_SERVICE + '/user',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/user',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/user',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/user',
      method: 'DELETE',
    },
    CHECK_USERNAME_EXISTED: {
      url: CMS_SERVICE + '/user/check',
      method: 'GET',
    },
    GET_USER_INFO: {
      url: CMS_SERVICE + '/user/get-user-info',
      method: 'GET',
    },
    GET_NOTIFICATIONS: {
      url: HRM_SYSTEM + '/notify',
      method: 'GET',
    },
    GET_TOTAL_NOTIFICATION_UN_READ: {
      url: HRM_SYSTEM + '/notify/count',
      method: 'GET',
    },
    MARK_READ_NOTIFICATION: {
      url: CMS_SERVICE + '/notify/seen',
      method: 'PATCH',
    },
    PATCH_UPDATE: {
      url: CMS_SERVICE + '/user',
      method: 'PATCH',
    },
    RESET_PASSWORD: {
      url: CMS_SERVICE + '/user/',
      method: 'PUT',
    },
    CHANGE_STATUS: {
      url: CMS_SERVICE + '/user/change-status/',
      method: 'PUT',
    },
  },
  auth: {
    LOGIN: {
      url: CMS_SERVICE + '/oauth/token',
      method: 'POST',
    },
    LOGOUT: {
      url: CMS_SERVICE + '/oauth/revoke',
      method: 'DELETE',
    },
    REFRESH_TOKEN: {
      url: CMS_SERVICE + '/oauth/refresh-token',
      method: 'POST',
    },
    PATCH_UPDATE: {
      url: CMS_SERVICE + '/user',
      method: 'PATCH',
    },
    RE_CAPTCHA: {
      url: CMS_SERVICE + '/captcha/generate',
      method: 'GET',
    },
  },
  upload_service: {
    UPLOAD: {
      url: HRM_SYSTEM + '/upload',
      method: 'POST',
    },
  },
  articles: {
    ALL: {
      url: CMS_SERVICE + '/article/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/article',
      method: 'GET',
    },
    SEARCH_APPROVE: {
      url: CMS_SERVICE + '/article/approve',
      method: 'GET',
    },
    SEARCH_PUBLIC: {
      url: CMS_SERVICE + '/article/publish',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/article',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/article',
      method: 'PUT',
    },
    UPDATE_STATUS: {
      url: CMS_SERVICE + '/article/update-status',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/article',
      method: 'DELETE',
    },
    UN_PUBLISH: {
      url: CMS_SERVICE + '/article/un-publish',
      method: 'PATCH',
    },
    APPROVAL: {
      url: CMS_SERVICE + '/article/approve',
      method: 'PATCH',
    },
    PUBLISH: {
      url: CMS_SERVICE + '/article/publish',
      method: 'PATCH',
    },
    UPDATE_HOT: {
      url: CMS_SERVICE + '/article/update-is-hot',
      method: 'PUT',
    },
  },
  media_files: {
    ALL: {
      url: CMS_SERVICE + '/media-files/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/media-files',
      method: 'GET',
    },
    SEARCH_APPROVE: {
      url: CMS_SERVICE + '/media-files/approve',
      method: 'GET',
    },
    SEARCH_PUBLIC: {
      url: CMS_SERVICE + '/media-files/publish',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/media-files',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/media-files',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/media-files',
      method: 'DELETE',
    },
    UN_PUBLISH: {
      url: CMS_SERVICE + '/media-files/un-publish',
      method: 'PATCH',
    },
    APPROVAL: {
      url: CMS_SERVICE + '/media-files/approve',
      method: 'PATCH',
    },
    PUBLISH: {
      url: CMS_SERVICE + '/media-files/publish',
      method: 'PATCH',
    },
  },
  chart: {
    DOMAIN_IP: {
      url: CMS_SERVICE + '/chart/account-domain-ip',
      method: 'GET',
    },
    ORGANIZATION: {
      url: CMS_SERVICE + '/chart/organization',
      method: 'GET',
    },
    STATUS: {
      url: CMS_SERVICE + '/chart/status',
      method: 'GET',
    },
    SUCCESS_STATUS: {
      url: CMS_SERVICE + '/chart/success-or-not',
      method: 'GET',
    },
    SUMMARY: {
      url: CMS_SERVICE + '/chart/summary',
      method: 'GET',
    },
    MANAGE_DATA: {
      url: CMS_SERVICE + '/agency/get-chart',
      method: 'GET',
    },
    PROVIDE_DATA: {
      url: CMS_SERVICE + 'agency/get-chart-dataset',
      method: 'GET',
    },
    FIELD_DATA: {
      url: CMS_SERVICE + '/fields/chart-field-dataset',
      method: 'GET',
    },
    TOTAL_FILE: {
      url: CMS_SERVICE + '/file/total-files',
      method: 'GET',
    },
    TOTAL_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/total-files-upload-by-month',
      method: 'GET',
    },
    TOTAL_FILE_DOWNLOAD: {
      url: CMS_SERVICE + '/file/total-files-download-by-month',
      method: 'GET',
    },
    TOTAL_AGENCIES_UPLOADED: {
      url: CMS_SERVICE + '/file/total-agencies-uploaded',
      method: 'GET',
    },
    TOP_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/top-10',
      method: 'GET',
    },
    TOP_AGENCIES: {
      url: CMS_SERVICE + '/file/top-10-agencies',
      method: 'GET',
    },
    FILE_EXTENSION: {
      url: CMS_SERVICE + '/file/files-extension',
      method: 'GET',
    },
  },
  procedure: {
    SEARCH: {
      url: CMS_SERVICE + '/procedure',
      method: 'GET',
    },
    CATEGORIES: {
      url: CMS_SERVICE + '/procedure/categories',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/procedure',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/procedure',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/procedure',
      method: 'DELETE',
    },
  },
  qAndA: {
    SEARCH: {
      url: CMS_SERVICE + '/qa',
      method: 'GET',
    },
    SEARCH_FA: {
      url: CMS_SERVICE + '/qa/frequently-asked',
      method: 'GET',
    },
    SEARCH_QA: {
      url: CMS_SERVICE + '/qa/q-and-a',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/qa',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/qa',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/qa',
      method: 'DELETE',
    },
  },
  topics: {
    GET_ALL: {
      url: CMS_SERVICE + '/topics/all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/topics',
      method: 'GET',
    },
    GET_BY_ID: {
      url: CMS_SERVICE + '/topics/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/topics/create',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/topics/update',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/topics/',
      method: 'DELETE',
    },
  },
  APP_PARAM: {
    SEARCH: {
      url: HRM_SYSTEM + '/app-params',
      method: 'GET',
    },
    GET_ALL_COMPANY: {
      url: CMS_SERVICE + '/company',
      method: 'GET',
    },
  },
  legislation: {
    SEARCH: {
      url: CMS_SERVICE + '/legislation',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/legislation',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/legislation',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/legislation',
      method: 'DELETE',
    },
  },
  introduce: {
    SEARCH: {
      url: CMS_SERVICE + '/introduce',
      method: 'GET',
    },
    CATEGORIES: {
      url: CMS_SERVICE + '/introduce/categories',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/introduce',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/introduce',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/introduce',
      method: 'DELETE',
    },
  },
  banners: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/banners',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/banners/all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/banners/create',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/banners/update',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/banners/',
      method: 'DELETE',
    },
  },
  config: {
    agency: {
      GET_ALL: {
        url: CMS_SERVICE + '/agency/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/agency',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/agency',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/agency',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/agency/',
        method: 'PUT',
      },
    },
    majors: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-major/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-major',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-major',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-major',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-major/',
        method: 'PUT',
      },
    },
    groups: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-group/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-group',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-group',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-group',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-group/',
        method: 'PUT',
      },
    },
    fields: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-fields/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-fields',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-fields',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-fields',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-fields/',
        method: 'PUT',
      },
    },
    types: {
      GET_ALL: {
        url: CMS_SERVICE + '/document-type/get-all',
        method: 'GET',
      },
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/document-type',
        method: 'GET',
      },
      CREATE: {
        url: CMS_SERVICE + '/document-type',
        method: 'POST',
      },
      UPDATE: {
        url: CMS_SERVICE + '/document-type',
        method: 'PUT',
      },
      DELETE: {
        url: CMS_SERVICE + '/document-type/',
        method: 'PUT',
      },
    },
  },
  recruitment: {
    GET_ALL: {
      url: CMS_SERVICE + '/recruitment/all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/recruitment',
      method: 'GET',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/recruitment/detail',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/recruitment',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/recruitment',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/recruitment/',
      method: 'DELETE',
    },
  },
  CONTACT: {
    getAll: {
      url: CMS_SERVICE + '/contact',
      method: 'GET',
    },
    updateView: {
      url: CMS_SERVICE + '/contact/',
      method: 'PUT',
    },
    delete: {
      url: CMS_SERVICE + '/contact/',
      method: 'DELETE',
    },
  },
  caseStudy: {
    GET_WEB_URL: {
      url: CMS_SERVICE + '/web-url',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/case-study/get-all',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: CMS_SERVICE + '/case-study',
      method: 'GET',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/case-study/',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/case-study',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/case-study',
      method: 'PUT',
    },
    UPDATE_STATUS: {
      url: CMS_SERVICE + '/case-study/update-status',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/case-study/',
      method: 'DELETE',
    },
  },
  UNIT: {
    LIST_UNIT: {
      url: HRM_SYSTEM + '/units/by-condition',
      method: 'GET',
    },
    UPDATE_DEPARTMENT: {
      url: HRM_SYSTEM + '/departments/update',
      method: 'PUT',
    },
    CREATE_DEPARTMENT: {
      url: HRM_SYSTEM + '/departments/create',
      method: 'POST',
    },
    UPDATE_UNIT: {
      url: HRM_SYSTEM + '/units/update',
      method: 'PUT',
    },
    CREATE_UNIT: {
      url: HRM_SYSTEM + '/units/create',
      method: 'POST',
    },
    DELETE_UNIT: {
      url: HRM_SYSTEM + '/units/delete',
      method: 'DELETE',
    },
    DELETE_DEPARTMENT: {
      url: HRM_SYSTEM + '/departments/delete',
      method: 'DELETE',
    },
    DELETE_DEPARTMENT_PROFILE: {
      url: HRM_SYSTEM + '/profiles/remove-department',
      method: 'POST',
    },
    DELETE_UNIT_PROFILE: {
      url: HRM_SYSTEM + '/profiles/remove-unit',
      method: 'POST',
    },
    ADD_DEPARTMENT_PROFILE: {
      url: HRM_SYSTEM + '/profiles/add-department',
      method: 'POST',
    },
    LIST_DEPARTMENT: {
      url: HRM_SYSTEM + '/departments/by-condition',
      method: 'GET',
    },
    EXPORT_DEPARTMENT_PROFILE: {
      url: HRM_SYSTEM + '/export/department-profiles',
      method: 'GET',
    },
    EXPORT_UNIT_PROFILE: {
      url: HRM_SYSTEM + '/export/unit-profiles',
      method: 'GET',
    },
    GET_ALL_UNIT: {
      url: HRM_SYSTEM + '/units/by-condition',
      method: 'GET',
    },
  },
  WORK_SHIFT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/workshifts/by-condition',
      method: 'GET',
    },
  },
  profileManagement: {
    GET_ALL: {
      url: HRM_SYSTEM + '/profiles',
      method: 'GET',
    },
    GET_LIST_NON_ACCOUNT: {
      url: HRM_SYSTEM + '/profiles/non-account',
      method: 'GET',
    },
    GET_LIST_NAME: {
      url: HRM_SYSTEM + '/profiles/get-list-name',
      method: 'GET',
    },
    GET_BY_CONDITIONS: {
      url: HRM_SYSTEM + '/profiles/verify',
      method: 'GET',
    },
    GET_BY_IDS: {
      url: HRM_SYSTEM + '/profiles/',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/profiles',
      method: 'POST',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/profiles',
      method: 'PUT',
    },
    DELETE: {
      url: HRM_SYSTEM + '/profiles/',
      method: 'DELETE',
    },
    GET_ALL_UNIT: {
      url: HRM_SYSTEM + '/units/by-condition',
      method: 'GET',
    },
    GET_REGISTER_IN_OUT: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'GET',
    },
    GET_BY_UNIT_ID: {
      url: HRM_SYSTEM + '/departments/by-condition',
      method: 'GET',
    },
    GET_INFO: {
      url: HRM_SYSTEM + '/profiles/get-info',
      method: 'GET',
    },
    IMPORT_EXCEL: {
      url: HRM_SYSTEM + '/export/import-profiles-file',
      method: 'POST',
    },
    DOWNLOAD_FILE: {
      url: HRM_SYSTEM + '/export/profiles-base-file',
      method: 'GET',
    },
    HISTORY_IMPORT_FILE: {
      url: HRM_SYSTEM + '/export/import-history',
      method: 'GET',
    },
    EXPORT_PROFILE: {
      url: HRM_SYSTEM + '/export/profiles',
      method: 'GET',
    },
  },
  ATTENDANCE: {
    GET_ALL: {
      url: HRM_SYSTEM + '/attendances/records',
      method: 'GET',
    },
    CHANGE_STATUS: {
      url: HRM_SYSTEM + '/attendances/toggle-status',
      method: 'POST',
    },
    EXPORT: {
      url: HRM_SYSTEM + '/export/attendance-records',
      method: 'GET',
    },
    EXPORT_STAFF: {
      url: HRM_SYSTEM + '/export/staff-timekeeping',
      method: 'GET',
    },
    AGGREGATE: {
      url: HRM_SYSTEM + '/attendances/aggregate',
      method: 'GET',
    },
    EXPORT_DETAIL: {
      url: HRM_SYSTEM + '/export/aggregate',
      method: 'GET',
    },
    EXPORT_GENERAL: {
      url: HRM_SYSTEM + '/export/timekeeping-report',
      method: 'GET',
    },
    STAFF_TIMEKEEPING: {
      url: HRM_SYSTEM + '/attendances/staff-timekeeping',
      method: 'GET',
    },
  },
  ATTENDANCE_REPORT: {
    GET_TODAY_TIMEKEEPING: {
      url: HRM_SYSTEM + '/statistic/today-timekeeping',
      method: 'GET',
    },
    GET_UNIT_STAFF: {
      url: HRM_SYSTEM + '/statistic/unit-staff',
      method: 'GET',
    },
    GET_UNIT_TIMEKEEPING: {
      url: HRM_SYSTEM + '/statistic/unit-timekeeping',
      method: 'GET',
    },
  },
  TIME: {
    GET_ALL: {
      url: HRM_SYSTEM + '/workshifts/by-condition',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/workshifts/create',
      method: 'POST',
    },
    DELETE: {
      url: HRM_SYSTEM + '/workshifts/delete',
      method: 'DELETE',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/workshifts/update',
      method: 'PUT',
    },
    GET_DETAIL: {
      url: HRM_SYSTEM + '/workshifts/by-id',
      method: 'GET',
    },
  },
  LIST_UNIT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/units/all',
      method: 'GET',
    },
  },
  LIST_DEPARTMENT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/departments/by-condition',
      method: 'GET',
    },
  },
  LIST_BUILDING: {
    GET_ALL: {
      url: HRM_SYSTEM + '/buildings/all-building',
      method: 'GET',
    },
  },
  nationality: {
    GET_ALL: {
      url: HRM_SYSTEM + '/nationality',
      method: 'GET',
    },
  },
  province: {
    GET_ALL: {
      url: HRM_SYSTEM + '/provinces/get-all',
      method: 'GET',
    },
  },
  EVENT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/events',
      method: 'GET',
    },
    DELETE: {
      url: HRM_SYSTEM + '/events/',
      method: 'DELETE',
    },
    GET_DETAIL: {
      url: HRM_SYSTEM + '/events/',
      method: 'GET',
    },
    STATISTIC_DOOR: {
      url: HRM_SYSTEM + '/statistic/lane-control',
      method: 'GET',
    },
    IN_OUT_EVENT: {
      url: HRM_SYSTEM + '/events/in-out',
      method: 'GET',
    },
    INTRUSION_EVENT: {
      url: HRM_SYSTEM + '/events/intrusion',
      method: 'GET',
    },
    LANE_CONTROL_EVENT: {
      url: HRM_SYSTEM + '/events/lane-control',
      method: 'GET',
    },
    SENSITIVE_EVENT: {
      url: HRM_SYSTEM + '/events/sensitive',
      method: 'GET',
    },
    MONITOR_EVENT: {
      url: HRM_SYSTEM + '/events/monitor',
      method: 'GET',
    },
    HUMAN_COUNT_EVENT: {
      url: HRM_SYSTEM + '/events/human-count',
      method: 'GET',
    },
    MONITOR_STATISTIC: {
      url: HRM_SYSTEM + '/statistic/event-monitor',
      method: 'GET',
    },
    HUMAN_MONITOR_STATISTIC: {
      url: HRM_SYSTEM + '/statistic/human-monitor',
      method: 'GET',
    },
    HUMAN_IMAGE: {
      url: HRM_SYSTEM + '/events/human-by-img',
      method: 'GET',
    },
    VIDEO_EVENT:{
      url: VMS_SERVICE + '/api/recording',
      method: 'GET',
    }
  },
  CAMERA: {
    GET_CAMERA_IN_GROUP: {
      url: HRM_SYSTEM + '/cameras',
      method: 'GET',
    },
    GROUP_CAMERA: {
      url: VMS_SERVICE + '/api/cameragroups',
      method: 'GET',
    },
  },
  REGISTER_IN_OUT: {
    GET_ALL: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'POST',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'PUT',
    },
  },
  ACCESS_CONTROL: {
    DASHBOARD: {
      url: HRM_SYSTEM + '/statistic/in-out',
      method: 'GET',
    },
    DASHBOARD_TABLE: {
      url: HRM_SYSTEM + '/register-in-out',
      method: 'GET',
    },
    DASHBOARD_STRANGER: {
      url: HRM_SYSTEM + '/statistic/stranger',
      method: 'GET',
    },
    DASHBOARD_PROFILE_IN_OUT: {
      url: HRM_SYSTEM + '/statistic/profile-in-out',
      method: 'GET',
    },
    DOWNLOAD_FILE: {
      url: HRM_SYSTEM + '/register-in-out/export-excel',
      method: 'GET',
    },
    GET_ALL_CAMERA: {
      url: HRM_SYSTEM + '/cameras',
      method: 'GET',
    },
    GET_DATA_CHART: {
      url: HRM_SYSTEM + '/statistic/intrusion',
      method: 'GET',
    },
    GET_EVENT_INSTRUSION: {
      url: HRM_SYSTEM + '/events/intrusion',
      method: 'GET',
    },
  },
  dashboard: {
    GET_REPORT_EXIT_EARLY: {
      url: HRM_SYSTEM + '/dashboards/stat-exit-early',
      method: 'GET',
    },
    GET_TOP_EXIT_EARLY: {
      url: HRM_SYSTEM + '/dashboards/top-exit-early',
      method: 'GET',
    },
    GET_REPORT_LATED: {
      url: HRM_SYSTEM + '/dashboards/stat-lated',
      method: 'GET',
    },
    GET_TOP_LATED: {
      url: HRM_SYSTEM + '/dashboards/top-lated',
      method: 'GET',
    },
    GET_REPORT_DAY_OFF: {
      url: HRM_SYSTEM + '/dashboards/stat-day-off',
      method: 'GET',
    },
  },
  BUILDING: {
    BUILDING_BY_CONDITION: {
      url: HRM_SYSTEM + '/buildings/building-by-condition',
      method: 'GET',
    },
    FLOOR_BY_BUILDING_ID: {
      url: HRM_SYSTEM + '/buildings/all-floor',
      method: 'GET',
    },
    FLOOR_BY_CONDITION: {
      url: HRM_SYSTEM + '/buildings/floor-by-condition',
      method: 'GET',
    },
    FLOOR_BY_UNIT_ID: {
      url: HRM_SYSTEM + '/buildings/floor-by-unit-id',
      method: 'GET',
    },
    UNIT_BY_FLOOR: {
      url: HRM_SYSTEM + '/buildings/unit-by-floor',
      method: 'GET',
    },
    GET_NAME_BUILDING_ID: {
      url: HRM_SYSTEM + '/building',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/buildings/create-building',
      method: 'POST',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/buildings/update-building',
      method: 'PUT',
    },
    DELETE: {
      url: HRM_SYSTEM + '/buildings/delete-building',
      method: 'DELETE',
    },
    ADD_UNIT_FLOOR: {
      url: HRM_SYSTEM + '/buildings/add-unit-floor',
      method: 'POST',
    },
    DELETE_UNIT_FLOOR: {
      url: HRM_SYSTEM + '/buildings/remove-unit-floor',
      method: 'DELETE',
    },
    UNIT_BY_CONDITION: {
      url: HRM_SYSTEM + '/units/by-condition',
      method: 'GET',
    },
    DOWNLOAD_FILE: {
      url: HRM_SYSTEM + '/export/unit-floor',
      method: 'GET',
    },
  },
  LANGUAGE: {
    GET_LANG: {
      url: HRM_SYSTEM + '/lang-pack/',
      method: 'GET',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/lang-pack/update',
      method: 'POST',
    },
    RESET: {
      url: HRM_SYSTEM + '/lang-pack/reset',
      method: 'POST',
    },
  },
  PARTNER: {
    GET_ALL: {
      url: HRM_SYSTEM + '/partners/by-condition',
      method: 'GET',
    },
    GET_BY_ID: {
      url: HRM_SYSTEM + '/partners/by-id',
      method: 'GET',
    },
    GET_INFO: {
      url: HRM_SYSTEM + '/partners/get-info',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/partners/create',
      method: 'POST',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/partners/update',
      method: 'PUT',
    },
    DELETE: {
      url: HRM_SYSTEM + '/partners/delete',
      method: 'DELETE',
    },
    GET_MENU: {
      url: CMS_SERVICE + '/partner-menu',
      method: 'GET',
    },
    RESET_PARTNER_PASSWORD: {
      url: HRM_SYSTEM + '/partners/set-default-password/',
      method: 'PUT',
    },
  },
  STATISTIC: {
    INDIVIDUAL_TIMEKEEPING: {
      url: HRM_SYSTEM + '/statistic/individual-timekeeping',
      method: 'GET',
    },
    ACCESS_CONTROL: {
      url: HRM_SYSTEM + '/statistic/access-control',
      method: 'GET',
    },
    COMPARE_ACCESS_CONTROL: {
      url: HRM_SYSTEM + '/statistic/compare-access-control',
      method: 'GET',
    },
  },
  HOME_PAGE: {
    GET_BOX_AI: {
      url: HRM_SYSTEM + '/box-info',
      method: 'GET',
    },
    GET_CAMERA_STATUS: {
      url: VMS_SERVICE + '/api/cameras',
      method: 'GET',
    },
  },
  LOG: {
    GET_ALL: {
      url: HRM_SYSTEM + '/logs',
      method: 'GET',
    },
    EXPORT_LOG: {
      url: HRM_SYSTEM + '/logs/export',
      method: 'GET',
    },
  },
  AI_BOX: {
    GET_LIST_AI_BOX: {
      url: HRM_SYSTEM + '/box-info/by-condition',
      method: 'GET',
    },
    GET_PARTNER: {
      url: HRM_SYSTEM + '/partners/all',
      method: 'GET',
    },
  },
  HOME: {
    GET_ALL: {
      url: HRM_SYSTEM + '/partners/statistic',
    },
  },
  REGISTRATION_REPORT: {
    STATISTIC_REGISTER_IN_OUT: {
      url: HRM_SYSTEM + '/statistic/register-in-out',
      method: 'GET',
    },
    MOST_REGISTER_IN_OUT: {
      url: HRM_SYSTEM + '/register-in-out/most',
      method: 'GET',
    },
  },
  ALERT_CONFIGURATION: {
    GET_ALL: {
      url: HRM_SYSTEM + '/notify-config',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/notify-config',
      method: 'POST',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/notify-config',
      method: 'PUT',
    },
    DELETE: {
      url: HRM_SYSTEM + '/notify-config',
      method: 'DELETE',
    },
    GET_PROFILE_BY_IDS_OR_TYPE: {
      url: HRM_SYSTEM + '/profiles/by-id-or-type',
      method: 'POST',
    },
    GET_ALERT_BY_ID: {
      url: HRM_SYSTEM + '/notify-config/',
      method: 'GET',
    },
    GET_NOTIFY_SETTING: {
      url: HRM_SYSTEM + '/notify-setting',
      method: 'GET',
    },
    UPDATE_NOTIFY_SETTING: {
      url: HRM_SYSTEM + '/notify-setting',
      method: 'POST',
    },
  },
  DEVICE: {
    GET_LIST_DEVICE: {
      url: VMS_SERVICE + '/api/cameragroups/devices',
      method: 'GET',
    },
    SCAN_CAMERA_IN_BOX: {
      url: VMS_SERVICE + '/api/cameragroups/discovery-info',
      method: 'POST',
    },
    STREAM_CAMERA: {
      url: VMS_SERVICE + '/api/stream/url',
      method: 'GET',
    },
    SCAN_CAMERA_OUT_GROUP: {
      url: VMS_SERVICE + '/api/cameras/discovery',
      method: 'POST',
    },
    GET_LIST_SCAN_CAMERA_OUT_GROUP: {
      url: VMS_SERVICE + '/api/discovered-camera/not-in-group',
      method: 'GET',
    },
    CREATE_DISCOVERY_IN_GROUPS: {
      url: VMS_SERVICE + '/api/cameragroups/discovery-info',
      method: 'POST',
    },
    ADD_CAM_IN_BOX: {
      url: VMS_SERVICE + '/api/cameras/add-discovered-camera',
      method: 'POST',
    },
    CREATE_AI: {
      url: VMS_SERVICE + '/api/aiflows',
      method: 'POST',
    },
    GET_CAMERA_IN_GROUP: {
      url: VMS_SERVICE + '/api/cameragroups',
      method: 'GET',
    },
    CREATE_ZONE: {
      url: VMS_SERVICE + '/api/polygons',
      method: 'POST',
    },
    CREATE_AI_FLOW: {
      url: VMS_SERVICE + '/api/aiflows',
      method: 'POST',
    },
    ADD_ZONE_AI_FLOWS: {
      url: VMS_SERVICE + '/api/aiflows/add-polygons',
      method: 'POST',
    },
    ADD_AI_CAMERA: {
      url: VMS_SERVICE + '/api/cameras/add-aiflows',
      method: 'POST',
    },
    GET_LIST_AI_FLOWS: {
      url: VMS_SERVICE + '/api/cameras',
      method: 'GET',
    },
    REMOVE_AI_FLOWS: {
      url: VMS_SERVICE + '/api/aiflows',
      method: 'DELETE',
    },
    GET_AI_FLOWS: {
      url: VMS_SERVICE + '/api/aiflows',
      method: 'GET',
    },
    CREATE_DISCOVERY_OUT_GROUP: {
      url: VMS_SERVICE + '/api/discovery-info',
      method: 'POST',
    },
    LIST_OF_SCANNED_CAMERAS: {
      url: VMS_SERVICE + '/api/cameragroups?detail=true',
      method: 'GET',
    },
    GET_CAMERA_NOT_IN_GROUP: {
      url: VMS_SERVICE + '/api/discovered-camera/not-in-group',
      method: 'GET',
    },
    EDIT_CAMERA: {
      url: VMS_SERVICE + '/api/cameras',
      method: 'PATCH',
    },
    DELETE_CAMERA_NOT_IN_GROUP: {
      url: VMS_SERVICE + '/api/cameras',
      method: 'PATCH',
    },
    SCAN_CAMERA_OUT_GROUP_SINGLE: {
      url: VMS_SERVICE + '/api/cameras/discovery/single',
      method: 'POST',
    },
    UPDATE_AI_BOX: {
      url: VMS_SERVICE + '/api/cameragroups',
      method: 'PATCH',
    },
    REMOVE_CAMERA_IN_AI_BOX: {
      url: VMS_SERVICE + '/api/discovered-camera',
      method: 'PATCH',
    },
    UPDATE_AI_FLOW: {
      url: VMS_SERVICE + '/api/cameragroups/apply-ai-flow',
      method: 'POST',
    },
    UPDATE_AI_FLOW_CAMERA: {
      url: VMS_SERVICE + '/api/cameras/apply-ai-flow',
      method: 'POST',
    },
    GET_AI_FLOW: {
      url: VMS_SERVICE + '/api/aiflows',
      method: 'GET',
    },

    GET_POLYGON: {
      url: VMS_SERVICE + '/api/polygons',
      method: 'GET',
    },
    DELETE_POLYGON: {
      url: VMS_SERVICE + '/api/polygons',
      method: 'DELETE',
    },
    EDIT_POLYGON: {
      url: VMS_SERVICE + '/api/polygons',
      method: 'PATCH',
    },
    CREATE_DEVICE_DOOR: {
      url: VMS_SERVICE + '/api/door',
      method: 'POST',
    },
    UPDATE_DEVICE_DOOR: {
      url: VMS_SERVICE + '/api/door',
      method: 'PATCH',
    },
    DELETE_DEVICE_DOOR: {
      url: VMS_SERVICE + '/api/door',
      method: 'DELETE',
    },
    GET_ALL_DOOR: {
      url: VMS_SERVICE + '/api/door',
      method: 'GET',
    },
    ADD_POLYGON_DOOR: {
      url: VMS_SERVICE + '/api/polygons/add-doors',
      method: 'POST',
    },
    SEARCH_CAMERA_IN_AI_BOX: {
      url: VMS_SERVICE + '/api/discovered-camera',
      method: 'GET',
    },
    GET_ID_AI_FLOW: {
      url: VMS_SERVICE + '/api/aiflows/get-by-camera-and-type',
      method: 'GET',
    },
    GET_RE_STREAM_AI: {
      url: VMS_SERVICE + '/api/stream/url-ai-flow',
      method: 'GET',
    },
    GET_GROUPS_TREE: {
      url: VMS_SERVICE + '/api/cameragroups/groups-tree',
      method: 'GET',
    },
    ADD_DOOR_IN_GROUP: {
      url: VMS_SERVICE + '/api/cameragroups/add-doors',
      method: 'POST',
    },
    ADD_BOX_IN_GROUP: {
      url: VMS_SERVICE + '/api/cameragroups/add-children-group',
      method: 'POST',
    },
    ADD_CAMERA_IN_GROUP: {
      url: VMS_SERVICE + '/api/cameragroups/add-cameras',
      method: 'POST',
    },
    CREATE_GROUP_DEVICES: {
      url: VMS_SERVICE + '/api/cameragroups',
      method: 'POST',
    },
    DELETE_GROUP_DEVICES: {
      url: VMS_SERVICE + '/api/cameragroups',
      method: 'DELETE',
    },
    GET_ALL_PARTNER: {
      url: HRM_SYSTEM + '/partners/by-condition',
      method: 'GET',
    },
    APPLY_AI_GROUP: {
      url: VMS_SERVICE + '/api/cameragroups/apply-ai-flow',
      method: 'POST',
    },
    GET_GROUP_CAMERA: {
      url: VMS_SERVICE + '/api/cameragroups',
      method: 'GET',
    },
    GET_CAMERA: {
      url: VMS_SERVICE + '/api/cameras',
      method: 'GET',
    },
    ACTIVE_GROUP: {
      url: VMS_SERVICE + '/api/cameragroups',
      method: 'PATCH',
    },
    DELETE_CAMERA_IN_GROUP: {
      url: VMS_SERVICE + '/api/cameragroups/remove-cameras',
      method: 'POST',
    },
    DELETE_BOX_IN_GROUP: {
      url: VMS_SERVICE + '/api/cameragroups/remove-children-group',
      method: 'POST',
    },
    DELETE_DOOR_IN_GROUP: {
      url: VMS_SERVICE + '/api/cameragroups/remove-doors',
      method: 'POST',
    },
  },
  leaveApplication: {
    GET_LIST_FOR_STAFF: {
      url: HRM_SYSTEM + '/leave-application/for-staff',
      method: 'GET',
    },
    GET_LIST_FOR_MANAGER: {
      url: HRM_SYSTEM + '/leave-application/for-manager',
      method: 'GET',
    },
    CREATE: {
      url: HRM_SYSTEM + '/leave-application/create',
      method: 'POST',
    },
    DETAIL: {
      url: HRM_SYSTEM + '/leave-application/by-id',
      method: 'GET',
    },
    APPROVE: {
      url: HRM_SYSTEM + '/leave-application/approve',
      method: 'PUT',
    },
  },
  holiday: {
    CREATE: {
      url: HRM_SYSTEM + '/holidays/create',
      method: 'POST',
    },
    LIST: {
      url: HRM_SYSTEM + '/holidays/by-condition',
      method: 'GET',
    },
    DETAIL: {
      url: HRM_SYSTEM + '/holidays/by-id',
      method: 'GET',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/holidays/update',
      method: 'PUT',
    },
    DELETE: {
      url: HRM_SYSTEM + '/holidays/delete',
      method: 'DELETE',
    },
  },
  emailConfig: {
    LIST: {
      url: HRM_SYSTEM + '/email-config',
      method: 'GET',
    },
    UPDATE: {
      url: HRM_SYSTEM + '/email-config',
      method: 'PUT',
    },
    UPDATE_PERSONAL_CONFIG: {
      url: HRM_SYSTEM + '/notify-attendance-config/personal',
      method: 'POST',
    },
    GET_PERSONAL_CONFIG: {
      url: HRM_SYSTEM + '/notify-attendance-config/personal',
      method: 'GET',
    },
    UPDATE_SUMMARY_CONFIG: {
      url: HRM_SYSTEM + '/notify-attendance-config/summary',
      method: 'POST',
    },
    GET_SUMMARY_CONFIG: {
      url: HRM_SYSTEM + '/notify-attendance-config/summary',
      method: 'GET',
    },
  },
  fireAlert: {
    GET_CONFIG: {
      url: HRM_SYSTEM + '/notify-fire-config',
      method: 'GET',
    },
    UPDATE_CONFIG: {
      url: HRM_SYSTEM + '/notify-fire-config',
      method: 'POST',
    },
    FIRE_EVENT: {
      url: HRM_SYSTEM + '/events/fire',
      method: 'GET',
    },
  },
};
