import mutations from '../articles/mutations';
import { getField, updateField } from 'vuex-map-fields';
import { trimValueObj } from '@/util/common-utils';
import { DataService } from '@/dataService/dataService';
import { notification } from 'ant-design-vue';
import { i18n } from '@/main';
import ConstantAPI from '@/config/ConstantAPI';
import { showConfirm } from '@/util/confirm';
import dayjs from 'dayjs';

const state = () => ({
  api: ConstantAPI.LOG,
});
const actions = {
  async export({ state }, params) {
    const { t } = i18n.global;
    try {
      if (await showConfirm(t('attendance.confirm-export'))) {
        trimValueObj(params);
        notification.info({
          message: t('common.notification'),
          description: t('common.exporting'),
          duration: 4,
        });
        const response = await DataService.getConfig(
          state.api.EXPORT_LOG.url,
          params,
          null,
          'blob'
        );
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `log_from_${dayjs(params.dateFrom).format('YYYY-MM-DD')}_to_${dayjs(params.dateTo).format('YYYY-MM-DD')}.xlsx`);
        document.body.appendChild(link);
        link.click();
        notification.success({
          message: t('common.notification'),
          description: t('common.export-success'),
          duration: 4,
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
